.product-item {
    .product-item-info {
        .product-item-name {
            height: calc(1.8rem * 2);
            line-height: 1.8rem;

            a.product-item-link {
                .platforms-icons .platforms-icon i {
                    font-size: 0.9rem;
                }
            }
        }
    }

}

body.contentmanager-contenttype-homepage {
    .homepage {
        .bestsellers {
            .bestsellers-row {

                .product-item-small {
                    .product-item-info {

                        .product-item-inner-long {
                            display: block;

                        }
                    }
                }
            }
        }
        .availablenow {
            .availablenow-row {

                .availablenow-product-item-small {
                    .product-item-info {

                        .product-item-inner-long {
                            display: block;

                        }
                    }
                }
            }
        }
    }
}
