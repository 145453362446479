.message {
    display: flex;
    justify-content: center;
    &:before {
        font-size: 1.4rem;
        padding-right: 1rem;
        padding-left: 0.5rem;
        margin-right: 0;
    }
}
