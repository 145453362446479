body.contentmanager-contenttype-homepage {
    .homepage {
        .slideshow {
            .splide {
                &__list {
                    @media (min-width: 992px) and (max-width: 1439px) {
                        max-height: 395px;
                    }
                }
            }
        }

        .bestsellers {
            .bestsellers-row {
                padding-left: 2.8rem;
                width: 100%;
                .product-item-small {
                    .product-item-info {
                        .product.details {
                            width: calc((100% - 148px) / 2);
                        }
                    }
                }
                .bestsellers-nb {
                    width: 2.2rem;
                    @include absolute-vertical-centring;
                    left: 0;
                    span {
                        filter: drop-shadow(0 0 8px $cyan-turquoise);
                    }
                }
            }
        }
        .availablenow {
            .availablenow-row {
                padding-left: 0;
                width: 100%;
                .product-item-small {
                    .product-item-info {
                        .product.details {
                            width: calc((100% - 148px) / 2);
                        }
                    }
                }
            }
        }
    }
}
