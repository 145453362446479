header.page-header {
    .header.content {
        .link-search {
            .block-search {
                .block-content {
                    .control {
                        input {
                            width: 240px;
                        }
                    }
                }
            }
        }
    }
    .top-line-banner .top-line-banner-item-wrapper {
        .item-reassurance {
            display: initial;
        }
        .item-trustpilot-widget {
            flex: initial;
        }
    }
    .minicart-wrapper {
        .action-wishlist,
        .action-minicart {
            margin-right: 25px;
        }
    }
}

.amsearch-wrapper-block {
    position: initial;
    display: flex;
    align-items: center;
    margin-top: 0;
    width: initial;
    margin-left: 40px;

    input.amsearch-input {
        width: 400px;
    }
}
