.checkout-cart-index {
    .page-main {
        .page-title-wrapper {
            display: flex;
            margin-top: 2rem;
            margin-left: 15px;
            font-size: 1.8rem;
            border-bottom: none;
            i:before {
                margin-left: 0.5rem;
                font-size: 1.6rem;
                margin-top: -0.5rem;
            }
        }
        .main {
            .cart-container {
                @media (min-width: 992px) {
                    display: flex;
                    flex-direction: row-reverse;
                }
                .cart-summary {
                    padding-left: 2rem;
                    border-left: solid $light-dark 1px;
                    .summary {
                        &.title {
                            display: none;
                        }
                    }
                    #block-shipping {
                        .shipping-main-title {
                            padding: 0;
                            font-size: 1.1rem;
                            &:after {
                                top: -3px;
                                font-size: 2.5rem;
                                position: relative;
                            }
                        }
                        &.active {
                            .shipping-main-title {
                                &:after {
                                    top: -3px;
                                    font-size: 2.5rem;
                                }
                            }
                        }
                    }
                    .content {
                        #discount-coupon-form {
                            .field {
                                margin: 0.5rem 0;
                                display: flex;
                                justify-content: space-between;
                            }
                            .control {
                                input {
                                    font-size: 0.8rem;
                                }
                            }
                            .btn-dark {
                                font-size: 0.8rem;
                            }
                        }
                        #co-shipping-method-form {
                        }
                        #shipping-zip-form {
                            label {
                                font-size: 1.1rem;
                                margin-top: 1rem;
                            }
                            .select {
                                font-size: 0.8rem;
                                margin-top: 0.5rem;
                                height: 2.5rem;
                                option {
                                    font-size: 0.8rem;
                                }
                            }
                        }
                    }
                    .cart-totals {
                        .table-wrapper {
                            .totals {
                                &.shipping {
                                    &.incl {
                                    }
                                }
                            }
                            .totals-tax {
                            }
                        }
                        .price {
                        }
                    }
                }
                .cart-item-wrapper {
                    .cart-items {
                        padding-right: 1rem;
                    }
                    .item-info {
                        display: flex;
                        margin-bottom: 2rem;
                        width: 100%;
                        .item {
                            display: flex;
                            flex-direction: column;
                            @media (min-width: 992px) {
                                display: flex;
                                flex-direction: row;
                            }
                            width: 100%;
                            .product-image-container {
                                .product-image-wrapper {
                                    padding-bottom: 0 !important;
                                    height: 150px;
                                    @media (min-width: 992px) {
                                        width: 250px;
                                    }
                                    img {
                                        height: 100%;
                                        width: 100%;
                                        object-fit: cover;
                                    }
                                }
                            }
                            .product-item-details {
                                position: relative;
                                width: 100%;
                                .item-cart-actions {
                                    display: flex;
                                    flex-direction: column;
                                    width: max-content;
                                    justify-content: flex-end;
                                    float: right;
                                    @media (min-width: 992px) {
                                        position: absolute;
                                        bottom: 0;
                                        right: 0;
                                        padding: 0.5rem;
                                    }
                                    .price-div {
                                        float: right;
                                        padding: 0.5rem;
                                        .cart-price {
                                            float: right;
                                            font-size: 1.5rem;
                                        }
                                    }
                                    .item-cart-action-options {
                                        display: flex;
                                        margin-top: 0;
                                        .qty {
                                            label {
                                                display: flex;
                                                font-size: 1rem;
                                                span {
                                                    margin-top: 0.75rem;
                                                }
                                                input {
                                                    margin-left: 0.5rem;
                                                    padding: 0.75rem;
                                                    width: 2.5rem;
                                                    height: 2.5rem;
                                                }
                                            }
                                        }
                                        .item-actions {
                                            .action-edit {
                                                margin-right: 1rem;
                                                height: 2.5rem;
                                                padding: 0.5rem;
                                                a {
                                                    font-size: 0.8rem;
                                                }
                                            }
                                            .action-delete {
                                                a {
                                                    height: 2.5rem;
                                                    width: 2.5rem;
                                                    i:before {
                                                        margin: 0.65rem 0;
                                                    }
                                                }
                                            }
                                            .actions-toolbar {
                                                display: flex;
                                                .towishlist {
                                                    display: none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .block.crosssell {
                .product-item {
                    .price-wrapper {
                        .price {
                            font-size: 1.5rem;
                        }
                    }
                    .product-item-actions {
                        .action-primary,
                        .action.primary {
                            padding: 0.75rem 1rem;
                            height: auto;
                            font-size: 1rem;
                        }
                        .stock.unavailable {
                            font-size: 1rem;
                        }
                    }
                }
            }
        }
    }
}
