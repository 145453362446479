.page-layout-checkout {
    //------------- Progress Bar ------------------
    .opc-progress-bar {

        height: 64px;
        margin-left: 10px;

        .opc-progress-bar-item {
            position: relative;

            &:first-child .opc-progress-bar-item-content {
                align-items: center;
                transform: translateX(-50%);
            }
            &:last-child .opc-progress-bar-item-content {
                align-items: center;
                transform: translateX(50%);
            }

            .opc-progress-bar-item-content-text {
                visibility: visible;
                font-size: 14px;
                letter-spacing: 1px;
            }
        }
        .opc-progress-bar-item._active {
            .opc-progress-bar-item-content:before {
                bottom: 18px;
                width: 2px;
                height: 2px;
                content: "";
                box-shadow: 0 0 8px 1px $pink-light;
                position: absolute;
            }
            .opc-progress-bar-item-content:after {
                @include font-awesome;
                position: absolute;
                bottom: 16px;
                color: $light;
                font-size: 8px;
                transform: scaleY(1.5);
                width: 10px;
                text-align: center;
                content: '\f0d7';
            }

            .opc-progress-bar-item-content-icon {
                filter: none;
            }

            .opc-progress-bar-item-content-text {
                color: $light;
            }

            &:last-child:after {
                transform:translateX(0);
            }
        }
        .opc-progress-bar-item._complete {

            .opc-progress-bar-item-content-icon {
                color: $magenta;
            }

            .opc-progress-bar-item-content-text {
                color: $magenta;
            }

            &:first-child:after {
                left: 0;
                transform: translateX(-50%);
            }
            &:last-child:after {
                right: 0;
                left: auto;
                transform: translateX(50%);
            }
        }
    }
}

