.amgdprjs-bar-template {
    .amgdprcookie-bar-container {
        width: initial;

        .amgdprcookie-buttons-block {
            //flex-direction: row-reverse;
            gap: 25px;

            .amgdprcookie-button.-allow,
            .amgdprcookie-button.-decline,
            .amgdprcookie-button.-settings {
                font-size: 1rem;
                line-height: 1.1rem;
                white-space: nowrap;
            }
        }
    }
}
