.block.newsletter {

    .title {
        .title-text {
            letter-spacing: 0.48px;
        }

        .subtitle-text {
            letter-spacing: 0.28px;
        }
    }
}

