body.account {
    .page-main {
        .columns .column.main {
            width: 70%;
            .actions-toolbar {
                button {
                    margin-top: 15px;
                    margin-bottom: 15px;
                }
            }
        }
        .page-title-wrapper {
            @media (min-width: 796px) {
                .page-title {
                    span {
                        i:before {
                            display: none;
                        }
                    }
                }
            }
        }
        .legend {
            font-size: 1.5rem;
        }
    }
    .page-main .sidebar-main {
        .account-nav-title,
        .block-collapsible-nav-title.title {
            border: 0 none;
            font-size: 1rem;
            margin-top: 5px;
        }
    }
    .account-nav-content {
        display: block !important;
    }
    .sidebar-main {
        padding-right: 2rem !important;
        padding: 0;
        width: 25%;

        .content.block-collapsible-nav-content {
            padding-top: 0;
        }
    }
}
