.wishlist-index-index {
    #wishlist-view-form {
        .btn-dark {
            margin-top: inherit;
            margin-bottom: inherit;
            margin-left: 10px;
            width: auto;
        }

        .tocart {
            width: auto;
        }
    }
}
