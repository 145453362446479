.coming-soon {
    &-info {
        &-countdown {
            .cm-countdown {
                gap: 0 12px;

                .block-available {
                    flex: 1 0 100%;
                    margin-bottom: 8px;
                }

                .block-days {
                    flex: 0 0 auto;
                }
            }

        }
    }
}
